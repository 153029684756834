import '../css/app.scss'
import inView from 'in-view'
import { initGalleries } from './initGalleries.js'
import { initVideo } from './initVideo.js'
import { reactContainers } from './reactContainers.jsx'
import { colors } from './colors.js'

const main = async () => {
  initScroll()
  setTimeout(initGalleries, 0)
  initVideo()
  initScrollDown()
  reactContainers()
  colors()
}

document.addEventListener('DOMContentLoaded', (event) => {
  main().then(() => {
  })
})

function initScroll () {
  inView('.project')
    .on('enter', el => {
      document.querySelectorAll('.js-nav-active').forEach(el => el.classList.remove('js-nav-active'))
      let nav = document.querySelector('#nav-' + el.id)
      nav.classList.add('js-nav-active')
      let name = el.querySelector('.name')
      document.querySelector('.nav-name-container').innerHTML = name.innerHTML
      document.querySelector('.nav-name-container').parentElement.style.background = name.style.color
      document.querySelector('.navbar').style.background = name.style.color
    })
    .on('exit', el => {
    })
  inView.offset(window.innerHeight / 2)
}

function  initScrollDown () {
  document.querySelector('.icon-scroll-down').addEventListener('click', () => {
    window.scrollBy(0, window.innerHeight)
  })
}
