import Flickity from 'flickity'

function initGalleries () {
    let galleries = document.querySelectorAll('.carousel')
    galleries.forEach(gallery => {
        let bullets = gallery.parentElement.parentElement.querySelectorAll('.bullet')
        let flkty = new Flickity(gallery, {
            lazyLoad: 1,
            prevNextButtons: false,
            pageDots: false,
            setGallerySize: false
          })
        bullets.forEach(bullet => {
            bullet.addEventListener('click', () => {
                let nodes = Array.prototype.slice.call(bullet.parentElement.children)
                let index = nodes.indexOf(bullet)
                flkty.select(index)
            })
        })
        flkty.on('select', event => {
            gallery.parentElement.parentElement.querySelector('.js-active-bullet').classList.remove('js-active-bullet')
            bullets[event].classList.add('js-active-bullet')
        }) 
    })
}

export { initGalleries }
