import React from 'react'
import MailchimpSubscribe from "react-mailchimp-subscribe"
import { Z_BLOCK } from 'zlib';

const url = "//stroom.us13.list-manage.com/subscribe/post?u=67263343e8942dd60495f90f2&amp;id=a78ba7c66e";


// simplest form (only email)
const SimpleForm = () => <MailchimpSubscribe url={url}/>

// use the render prop and your custom form
const McForm = () => (
    <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <CustomForm
        status={status}
        message={message}
        onValidated={formData => subscribe(formData)}
      />
    )}
  />
)

const CustomForm = ({ status, message, onValidated }) => {
    let email, name;
    const submit = () =>
      email &&
      name &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
        LNAME: name.value
      });
  
    return (
      <div className="form-container"
        style={{
          display: "inline-block"
        }}
      >
        {status === "sending" && <div style={{ color: "red" }}>sending...</div>}
        {status === "error" && (
          <div
            style={{ color: "red" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            style={{ color: "green" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        <input
          style={{ 
              }}
          ref={node => (name = node)}
          type="text"
          placeholder= {document.documentElement.lang === 'nl' ? 'Naam*' : 'Name*'}
        />
        <input
          style={{ }}
          ref={node => (email = node)}
          type="email"
          placeholder="Email"
        />
        <button style={{ }} onClick={submit}>
          {document.documentElement.lang === 'nl' ? 'Verstuur' : 'Submit'}
        </button>
      </div>
    );
  };

export { McForm }