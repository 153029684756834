import Player from '@vimeo/player'

function initVideo() {
  let videos = document.querySelectorAll('.video')
  videos.forEach(el => {
    let iframe = el.querySelector('iframe')
    let player = new Player(iframe)
    let overlay = el.querySelector('.play')
    overlay.addEventListener('click', (event) => {
      overlay.remove()
      player.play()
    })
  })
}

export { initVideo }