import ReactDOM from 'react-dom'
import React from 'react'
import { McForm } from './components/form.jsx'
import { Artists } from './components/artists.jsx'

function reactContainers() {
    ReactDOM.render(
            < Artists />,
        document.getElementById('artists-container')
      )    
    ReactDOM.render(
        <div className="artist-list">
            < McForm />
        </div>,
        document.getElementById('nb')
      )    
}

export { reactContainers }
