import React from 'react'
import { ArtistsTable } from './artistsTable.jsx'
import { Map } from './map.jsx'
import { Country } from './country.jsx'

import dataFromFile from './artists.json'

class Artists extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      country: '',
      filtered: [
        {
          id: '',
          value: ''
        }
      ],
      lenght: dataFromFile.length
    }
  }

  updateCountry (country) {
    let countryName = document.documentElement.lang === 'nl' ? dataFromFile.find(x => x.code === country).country : dataFromFile.find(x => x.code === country).countryen
    let lenght = dataFromFile.filter(x => x.code === country).length
    if (country !== this.state.country) {
      this.setState({
        country: country,
        filtered: [
          {
            id: document.documentElement.lang === 'nl' ? 'country' : 'countryen',
            value: countryName
          }
        ],
        lenght: lenght
      })
    } else {
      this.setState({
        country: '',
        filtered: [
          {
            id: 'country',
            value: ''
          }
        ],
        lenght: dataFromFile.length
      })
    }
  }

  resetCountry () {
    this.setState({
      country: '',
      filtered: [
        {
          id: '',
          value: ''
        }
      ],
      lenght: dataFromFile.length
    })
  }

  render () {
    return (
      <div className='artist-list'>
        <Map
          country={this.state.country}
          updateCountry={this.updateCountry.bind(this)}
        />
        <Country country={this.state.country} data={dataFromFile} resetCountry={this.resetCountry.bind(this)} />
        <ArtistsTable
          data={dataFromFile}
          filtered={this.state.filtered}
          lenght={this.state.lenght}
        />
      </div>
    )
  }
}

export { Artists }
