import React, { Component } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup
} from "react-simple-maps";

const activeCountries = [
  "AM",
  "BE",
  "BA",
  "BR",
  "US",
  "CA",
  "CY",
  "DK",
  "DE",
  "FI",
  "US",
  "FR",
  "GR",
  "GB",
  "US",
  "IT",
  "JP",
  "LV",
  "LT",
  "US",
  "MX",
  "US",
  "AT",
  "PT",
  "UK",
  "RS",
  "SK",
  "ES",
  "US",
  "CZ",
  "AE",
  "KR",
  "IT",
  "SE",
  "NZ",
  "AT"
];
const wrapperStyles = {
  width: "100%",
  maxWidth: 980,
  margin: "0 auto"
};

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = { country: "" };
  }

  clickHandler(id) {
    this.props.updateCountry(id);
  }

  render() {
    return (
      <div style={wrapperStyles}>
        <ComposableMap
          projectionConfig={{
            scale: 230,
            rotation: [0, 0, 0]
          }}
          width={980}
          height={551}
          style={{
            width: "100%",
            height: "auto"
          }}
        >
          <ZoomableGroup center={[0, 15]} disablePanning>
            <Geographies
              geography={"/assets/topo/world-110m.json"}
              disableOptimization
            >
              {(geographies, projection) =>
                geographies.map((geography, i, xxx) => {
                  let isActive = activeCountries.includes(
                    geography.properties.ISO_A2
                  );
                  let background = "#ECEFF1";
                  let hover = {
                    fill: background,
                    stroke: "#607D8B",
                    strokeWidth: 0.75,
                    outline: "none"
                  };

                  let clickHandler = "";
                  if (isActive) {
                    hover.fill = "yellow";
                    background = "#FF00FF";
                    clickHandler = this.clickHandler.bind(
                      this,
                      geography.properties.ISO_A2
                    );
                  }

                  if (this.props.country === geography.properties.ISO_A2) {
                    background = "Yellow";
                  }

                  return (
                    <Geography
                      refresh={this.state.country}
                      style={{
                        default: {
                          fill: background,
                          stroke: "#607D8B",
                          strokeWidth: 0.75,
                          outline: "none"
                        },
                        hover: hover,
                        pressed: {
                          fill: hover.fill,
                          stroke: "#607D8B",
                          strokeWidth: 0.75,
                          outline: "none"
                        }
                      }}
                      key={geography.properties.ISO_A2 + i}
                      geography={geography}
                      projection={projection}
                      onClick={clickHandler}
                    />
                  );
                })
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
      </div>
    );
  }
}
export { Map };
