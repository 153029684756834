import React from 'react'

class Country extends React.Component {
  constructor (props) {
    super(props)
    this.state = { country: '' }
  }

  updateCountry (country) {
    this.setState({
      country: country
    })
  }

  render () {
    let text = ''
    document.documentElement.lang === 'nl' ? text = 'Selecteer land' : text = 'Select country'
    if (this.props.country === '') {
      return <div className="selected-country">{ text }</div>
    } else {
      return (
        <div className="selected-country selected-country-button" onClick={this.props.resetCountry}>
          {'\u00d7'}{' '}
          {this.props.data.find(x => x.code === this.props.country).country}
        </div>
      )
    }
  }
}

export { Country }
